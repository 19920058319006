.topSpace{
    margin: 20px;
    margin-top: 100px;
    height: 70vh;
}
.tableSection{
    margin-left: 20px;


}
.topthrade{
    background-color: black;
    color: #fff;
}
.green{
    color: green;
}