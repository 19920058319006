.topSpace {
    margin: 20px;
    margin-top: 80px;
    height: 70vh;
    background-color: white;
}

.tableSection {
    margin-left: 20px;


}

.eachPcvImage {
    object-fit: contain;
    margin: auto !important;

}

.eachPcvImage div {

    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;

}

