/* .page-header {
  height: 100mm !important;
  position: fixed;
  top: 0mm;
  width: 100%;
} */

.page-header-space {
  height: 145mm !important;
 }

/* .page-footer,
.page-footer-space {
  height: 470px;
} */
.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.dataIN {
  vertical-align: bottom !important;
 
}

#footerImg {
  max-width: 100%;
  max-height: 100%;
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important; /* Optionally adjust left position if needed */
}


#headerImg {
  max-width: 100%;
  max-height: 100%;
   object-fit: fill;
  width: 296mm;
  display: block;
}

.page {
  page-break-after: always;
}

@page {
  margin: 0mm;
  size: A4;
  height: 100vh;
  background-color: red;
}


@media print {
  .fotter {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .header{
    .fotter {
      position: fixed;
      top: 0;
      width: 100%;
    } 
  }
}
@media print {
  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  button {
    display: none;
  }

  .body {
    margin: 0;
    height: 100vh;
    /* background-color: rebeccapurple; */
    position: relative;
  }
}


@media print {
  .A4 {
    page-break-before: always;
    page-break-inside: avoid;
    page-break-after: always;
  }
}


